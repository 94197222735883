.dde-head {
    position: relative;
    height: auto;
    width: 100%;
    padding: var(--header-height) 8%;
    font-size: 3em;
    background-color: white;
    z-index: 30;
    p {
        font-style: italic;
        color: var(--grey-400);
    }
}

.dde-img-holder, .dde-tx-holder {
    height: 100%;
}

.dde-img {
    width: 100%;
    svg {
        width: 100%;
        height: calc(#{width} * 1);
    }
}

.orange-img {
    display: block;
    margin: auto;
    height: 40vh;
    max-width: 70vw;
}

.display-images {
    display: block;
    max-height: 35vh;
    text-align:center;
    width: 100%;
    height: calc(100vw / 4);
    margin-bottom: 15px;

    img {
        width: auto; 
        height: 100%; 
        box-shadow: 3px 3px 4px rgba(0,0,0,0.3);
        margin: 7px;
    }
}

.display-section {
    padding: 0.5 1em ;
    font-size: 0.9em;
    border-bottom: solid 1px var(--grey-400);

    h4{
        color: var(--h4-color);
        margin-top: 1em;
        font-size: 1.2em;
    }
}

// This adds space to show the ad components in the desktop view
// .secondary-content > section { margin: 0 20vw 8vw 10vw; }
//Use this style instead of above line if not showing the ad components
.secondary-content > section { margin: 0 15vw 8vw 15vw; }

.dde-heading {
    font-weight: bold;
    font-size: 1.8em;
}


//desktop float styles
@media all and (min-width: 769px){

    // .desktop-none, wa-ads-mobile { display: none; }
    // replace above line with below for ad components
    .desktop-none { display: none; }


    .dde-img-holder { width: 50%; float: right; }
    .dde-img-holder, .dde-tx-holder { height: 100%; }
    .dde-img-holder { padding-top: 50px; }
    .dde-head { height: 95vh; }


}
// end desktop float styles

@media all and (max-width: 768px){

    // .mobile-none, wa-ads-desktop { display: none; }
    // replace above line with below for ad components
    .mobile-none { display: none; }

    .secondary-content > section { margin: 0 8vw 8vw 8vw; }
    .dde-img { width: 60%; }

}

@media all and (max-width: 450px){
    .dde-img { width: 100%; }
}


