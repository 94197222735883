* { box-sizing: border-box; margin: 0; }

#root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  
    overflow-y: auto;
    font-size: 100%;
    overflow-x: hidden;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.flex-center {
    display: flex; align-items: center; justify-content: center; 
}

main-app-header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 40;
}


body {
    position: relative;
    padding: 0;  
    margin: 0;
    height: 100vh;
    overflow: hidden; //this removes an unwanted full length scroll bar
}

main {
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    background-color: white;
    position: relative;
    z-index: 20;
    padding-bottom: 50px;
    margin-bottom: 150px;
    font-size: 1.5em;
    // font-size: calc(0.9em * 3vw);
}
//headers not included
#terms section {
    padding: 20px 10vw;
    h4 {
        padding: 1em 0;
    }

}
p {
    padding-bottom: 10px;
}




.page-head {
    padding-top: var(--header-height);
    min-height: 96vh;
}

.info-head{
    font-size: 2em;
    font-weight: bold;
    padding-top: var(--header-height);
    margin-top: var(--header-height);
    height: 150px;
    width: 100%;
    text-align: center;
}

.lost {
    padding: 2em;
    text-align: center;
}

h1 {
    color: var(--h1-color);
    font-size: 1.5em;
}

h2 {
    font-size: 1.5em;
    font-weight: 400;
    color: var(--h2-color);
    margin-bottom: 15px;

    span {
        font-weight: 700;
        font-size: 1.1em;
    }
}

h3 { 
    margin-top: 1em;
    font-size: 1.4em;
    color: var(--h3-color);
}


// footer {
//     width: 100%;
//     height: 250px;
//     margin-top: 30px;
//     padding-top: 100px;
//     padding-right: 15px;

//     background: var(--brand-gradient);        
//     color: white;
//     font-size: 3em;
//     font-weight: 700;
// }

main-app-footer {
    position: fixed; 
    bottom: 0;
    z-index: 1;
    width: 100%;
}






.clearfix::after {
    content: "";
    clear: both;
    display: table;
}


@media all and (max-width: 950px){

    .dde-head {
        font-size: 2.7em;
    }
    
}


@media all and (max-width: 749px){
    // footer {
    //     height: 200px;
    //     margin-top: 30px;
    //     padding-top: 50px;

    //     font-size: 2em;

    
    // }



    main {
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        padding-bottom: 30px;
        margin-bottom: 150px; //didn't change
        contain: paint;
        font-size: 1.3em;
    }

    .dde-head {
        font-size: 2.5em;
    }


}

// @media all and (max-width: 550px){

//     main { font-size: 1.2em; }
//     // h2 { font-size: 1.5em; }
//     .dde-head {
//         font-size: 1.9em;
//     }

// }


