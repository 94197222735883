
.contact-window {
    padding-top: 50px; 
    align-content: center; 
    max-width: 550px;
}

#form-head {
    padding-bottom: 20px;
}

.hidden { display: none;  }

input[type=text], input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 2px solid var(--input-border);
    border-radius: 10px;
    box-sizing: border-box;
    margin: 10px 0;
    resize: vertical;
    transition: border .3s ease-in-out;
    font-size: 0.7em; 
}
input:active, select:active, textarea:active, input:focus, select:focus, textarea:focus {
    border: solid 2px var(--input-active);  outline: none;
} 
input, textarea, label { font-family: Verdana, Tahoma, sans-serif; }
.required {
    color: var(--warning-color);
    font-size: 0.7em;  
    position: absolute;
    right: 7px;
    top:9px;
}
.input-holder { position: relative; width: 100%; }
.input-warning{ border: solid 2px var(--warning-color) !important; }

@media all and (max-width: 768px){
    .contact-window {
        padding-top: 0px; 
        margin: 0 5vw; 
    }
}
