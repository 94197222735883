

// home styles here
// hero section - see hero

#home-body {
    //see home for division styles


    // p, ul, h3, a {
    //     font-size: 1.6em;
    // }
    

    // h2 {
    //     font-size: 1.5em;
    //     font-weight: 400;
    //     color: var(--h2-color);
    //     margin-bottom: 15px;

    //     span {
    //         font-weight: 700;
    //         font-size: 1.1em;
    //     }
    // }
    


    .your{
        span:not(.hh-span, #webapp){
            font-style: italic;
            font-weight: 600;
            color: var(--highlight-color);
        }
    }

    section:not(#division) { 
        width: 70vw;
        margin: auto;
        margin-bottom: 50px;

        ul, ol { margin: 15px; }
    }


    #division {
        width: 90vw;
        margin: 50px auto;

        .float {
            text-align: center;
            padding: .5em;
        }

        .float-img {
            margin: .5em;
            height: 200px;
        }

        .float-text {
            margin: 1em;
            // width: 100%;
            text-align: justify;
            font-size: .9em;
        }

    }
}



// @media (prefers-reduced-motion: no-preference) {
//     .hidden {
//       transition: all 1s;
//     }
// }

.size-me {
    height: 100px;
    transition: all 2s;
}
//delay not working
.size-me:nth-child(2) {
    transition-delay: 200ms;
}
.size-me:nth-child(3) {
    transition-delay: 400ms;
}
.size-up {
    height: 200px;
}






#webapp {
    font-weight: bold;
    z-index: 50;
    background: var(--brand-gradient);        
    color: transparent; 
    -webkit-background-clip: text; 
    background-clip: text;
}

/* mobile styles */
@media all and (max-width: 1200px){

}

@media all and (max-width: 950px){

    // #home-body {
    
    //     p, ul { font-size: 1.5em; }
    //     h2 { font-size: 2.5em; }

    // }
}

//desktop float styles
@media all and (min-width: 750px){
    #home-body {
        .float {
            width: 33%;
            float: left;
        }

    }
}
// end desktop float styles

@media all and (max-width: 749px){
    #home-body {

        // p, ul, h3, a {
        //     font-size: 1.3em;
        // }

        // h2 { font-size: 2em; }
        .float {
            display: block;
            margin: auto;
            width: 70vw;
        }

    }
}

@media all and (max-width: 550px){

    #home-body {
    
        // p, ul { font-size: 1.1em; }
        // h2 { font-size: 1.5em; }

        section:not(#division) { 
            width: 90vw;
            margin-bottom: 40px;
            margin-top: 10px;
            ul { margin: 0; }
        }
        #division { 
            margin-bottom: 30px;
        }

    }

}