
.btn, button {
    text-decoration: none;
    background-color: var(--action-color);
    color: white;
    display: block;
    text-align: center;
    border-radius: 500px;
    outline: none;
    border: none;
    cursor: pointer;
}

.read-more {
    width: 200px;
    padding: 8px;
    margin: 1em auto;
} 

.contact-btn, .form-btn {
    width: 300px;
    padding: 10px;
    margin: 2em auto;
}

.contact-btn { font-size: 1.1em; }

.form-btn { font-size: 0.8em; }
