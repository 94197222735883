:root {
  --brand-colora-500: #f0f;
  --brand-colorb-500: #f700ff;
  --brand-colorc-500: #e300fb;
  --brand-colord-500: #c100f7;
  --brand-colore-500: #9200f1;
  --brand-colorf-500: #5600e9;
  --brand-colorg-500: #4600e7;
  --brand-gradient: linear-gradient(to top left, var(--brand-colora-500), var(--brand-colorg-500));
  --brand-colore-200: #d199f6;
  --brand-colore-300: #c474f9;
  --brand-colore-400: #aa39f5;
  --brand-colore-600: #8107d3;
  --brand-colore-700: #6705a8;
  --brand-colore-800: #520288;
  --brand-colorf-700: #4203b1;
  --grey-100: #edebeb;
  --grey-200: #dbd9d9;
  --grey-300: #b4b4b4;
  --grey-400: #9f9e9e;
  --grey-500: gray;
  --grey-600: #666;
  --grey-700: #494949;
  --grey-800: #2c2c2c;
  --grey-900: #101010;
  --header-height: 70px;
  --header-active: var(--brand-colorc-500);
  --header-hover: var(--brand-colore-500);
  --header-border: var(--brand-colord-500);
  --hero-anim-time: 6s;
  --action-color: var(--brand-colorg-500);
  --highlight-color: var(--brand-colore-800);
  --h1-color: var(--brand-colore-600);
  --h2-color: var(--brand-colore-700);
  --h3-color: var(--brand-colore-800);
  --h4-color: var(--brand-colorf-700);
  --input-border: var(--brand-colore-200);
  --input-active: var(--brand-colore-500);
  --warning-color: #dc9106;
  --success-color: #40d070;
}

* {
  box-sizing: border-box;
  margin: 0;
}

#root {
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
  font-size: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden auto;
}

.flex-center {
  justify-content: center;
  align-items: center;
  display: flex;
}

main-app-header {
  z-index: 40;
  width: 100%;
  position: fixed;
  top: 0;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

main {
  z-index: 20;
  background-color: #fff;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
  margin-bottom: 150px;
  padding-bottom: 50px;
  font-size: 1.5em;
  position: relative;
}

#terms section {
  padding: 20px 10vw;
}

#terms section h4 {
  padding: 1em 0;
}

p {
  padding-bottom: 10px;
}

.page-head {
  padding-top: var(--header-height);
  min-height: 96vh;
}

.info-head {
  padding-top: var(--header-height);
  margin-top: var(--header-height);
  text-align: center;
  width: 100%;
  height: 150px;
  font-size: 2em;
  font-weight: bold;
}

.lost {
  text-align: center;
  padding: 2em;
}

h1 {
  color: var(--h1-color);
  font-size: 1.5em;
}

h2 {
  color: var(--h2-color);
  margin-bottom: 15px;
  font-size: 1.5em;
  font-weight: 400;
}

h2 span {
  font-size: 1.1em;
  font-weight: 700;
}

h3 {
  color: var(--h3-color);
  margin-top: 1em;
  font-size: 1.4em;
}

main-app-footer {
  z-index: 1;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

@media (width <= 950px) {
  .dde-head {
    font-size: 2.7em;
  }
}

@media (width <= 749px) {
  main {
    contain: paint;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-bottom: 150px;
    padding-bottom: 30px;
    font-size: 1.3em;
  }

  .dde-head {
    font-size: 2.5em;
  }
}

#home-body .your span:not(.hh-span, #webapp) {
  color: var(--highlight-color);
  font-style: italic;
  font-weight: 600;
}

#home-body section:not(#division) {
  width: 70vw;
  margin: auto auto 50px;
}

#home-body section:not(#division) ul, #home-body section:not(#division) ol {
  margin: 15px;
}

#home-body #division {
  width: 90vw;
  margin: 50px auto;
}

#home-body #division .float {
  text-align: center;
  padding: .5em;
}

#home-body #division .float-img {
  height: 200px;
  margin: .5em;
}

#home-body #division .float-text {
  text-align: justify;
  margin: 1em;
  font-size: .9em;
}

.size-me {
  height: 100px;
  transition: all 2s;
}

.size-me:nth-child(2) {
  transition-delay: .2s;
}

.size-me:nth-child(3) {
  transition-delay: .4s;
}

.size-up {
  height: 200px;
}

#webapp {
  z-index: 50;
  background: var(--brand-gradient);
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: bold;
}

@media (width >= 750px) {
  #home-body .float {
    float: left;
    width: 33%;
  }
}

@media (width <= 749px) {
  #home-body .float {
    width: 70vw;
    margin: auto;
    display: block;
  }
}

@media (width <= 550px) {
  #home-body section:not(#division) {
    width: 90vw;
    margin-top: 10px;
    margin-bottom: 40px;
  }

  #home-body section:not(#division) ul {
    margin: 0;
  }

  #home-body #division {
    margin-bottom: 30px;
  }
}

.hero {
  width: 100%;
  height: 95vh;
  position: relative;
}

.hero-image-holder {
  width: 100%;
  height: 100%;
  padding-right: 90vw;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.hero-image-holder #hero-image-position {
  width: 50px;
  height: 50px;
  position: relative;
  transform: rotate(-10deg);
}

.hero-image-holder #hero-image-position img {
  transform-style: preserve-3d;
  width: 150vw;
  height: 150vh;
  max-height: 1700px;
  position: absolute;
  top: -65vh;
  left: -70vw;
}

#hero-img-logo {
  transform: perspective(5500px) rotateY(var(--rotatey)) rotateX(var(--rotatex));
}

#hero-img-shadow {
  transform: perspective(5500px) rotateY(var(--rotatey)) rotateX(var(--rotatex)) translateZ(-49px);
  filter: blur(5px);
}

#hero-img-grid {
  transform: perspective(5500px) rotateY(var(--rotatey)) rotateX(var(--rotatex)) translateZ(-50px);
}

.hero-text {
  z-index: 10;
  padding-left: 10vw;
  font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
  font-size: 3.5em;
}

.hero-name {
  font-size: 1.5em;
  font-weight: bold;
}

.hero-words {
  height: 120px;
  position: relative;
}

.hero-words p {
  margin-top: 0;
  position: absolute;
  top: 0;
}

#word1 {
  color: var(--brand-colore-500);
}

#word2 {
  color: var(--brand-colord-500);
}

#word3 {
  color: var(--brand-colorb-500);
}

@media (width <= 1200px) {
  .hero-image-holder {
    padding-right: 110vw;
  }

  .hero-text {
    padding-left: 20vw;
  }
}

@media (width <= 950px) {
  .hero-image-holder {
    padding-bottom: 35vh;
    padding-right: 70vw;
  }

  .hero-text {
    padding-top: 30vh;
    padding-left: 0;
    font-size: 3em;
  }
}

@media (width <= 600px) {
  .hero-image-holder {
    padding-bottom: 30vh;
    padding-right: 70vw;
  }

  .hero-text {
    padding-top: 40vh;
    font-size: 2.7em;
  }

  .hero-words {
    margin-top: 10vh;
  }
}

@media (width <= 450px) {
  .hero-text {
    font-size: 2em;
  }
}

#a1 {
  opacity: 0;
  animation: a1-a var(--hero-anim-time) infinite;
}

#a2 {
  opacity: 0;
  animation: a2-a var(--hero-anim-time) infinite;
}

#a3 {
  opacity: 0;
  animation: a3-a var(--hero-anim-time) infinite;
}

#a4 {
  opacity: 0;
  animation: a4-a var(--hero-anim-time) infinite;
}

#a5 {
  opacity: 0;
  animation: a5-a var(--hero-anim-time) infinite;
}

#a6 {
  opacity: 0;
  animation: a6-a var(--hero-anim-time) infinite;
}

#a7 {
  opacity: 0;
  animation: a7-a var(--hero-anim-time) infinite;
}

#a8 {
  opacity: 0;
  animation: a8-a var(--hero-anim-time) infinite;
}

#b1 {
  opacity: 0;
  animation: b1-a var(--hero-anim-time) infinite;
}

#b2 {
  opacity: 0;
  animation: b2-a var(--hero-anim-time) infinite;
}

#b3 {
  opacity: 0;
  animation: b3-a var(--hero-anim-time) infinite;
}

#b4 {
  opacity: 0;
  animation: b4-a var(--hero-anim-time) infinite;
}

#b5 {
  opacity: 0;
  animation: b5-a var(--hero-anim-time) infinite;
}

#b6 {
  opacity: 0;
  animation: b6-a var(--hero-anim-time) infinite;
}

#b7 {
  opacity: 0;
  animation: b7-a var(--hero-anim-time) infinite;
}

#b8 {
  opacity: 0;
  animation: b8-a var(--hero-anim-time) infinite;
}

#b9 {
  opacity: 0;
  animation: b9-a var(--hero-anim-time) infinite;
}

#c1 {
  opacity: 0;
  animation: c1-a var(--hero-anim-time) infinite;
}

#c2 {
  opacity: 0;
  animation: c2-a var(--hero-anim-time) infinite;
}

#c3 {
  opacity: 0;
  animation: c3-a var(--hero-anim-time) infinite;
}

#c4 {
  opacity: 0;
  animation: c4-a var(--hero-anim-time) infinite;
}

#c5 {
  opacity: 0;
  animation: c5-a var(--hero-anim-time) infinite;
}

#c6 {
  opacity: 0;
  animation: c6-a var(--hero-anim-time) infinite;
}

#c7 {
  opacity: 0;
  animation: c7-a var(--hero-anim-time) infinite;
}

#c8 {
  opacity: 0;
  animation: c8-a var(--hero-anim-time) infinite;
}

#c9 {
  opacity: 0;
  animation: c9-a var(--hero-anim-time) infinite;
}

#c10 {
  opacity: 0;
  animation: c10-a var(--hero-anim-time) infinite;
}

#c11 {
  opacity: 0;
  animation: c11-a var(--hero-anim-time) infinite;
}

@keyframes a1-a {
  1% {
    opacity: 0;
  }

  1.5%, 27.5% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }
}

@keyframes a2-a {
  2% {
    opacity: 0;
  }

  3%, 26% {
    opacity: 1;
  }

  27% {
    opacity: 0;
  }
}

@keyframes a3-a {
  4% {
    opacity: 0;
  }

  4.5%, 24.5% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }
}

@keyframes a4-a {
  5% {
    opacity: 0;
  }

  6%, 23% {
    opacity: 1;
  }

  24% {
    opacity: 0;
  }
}

@keyframes a5-a {
  7% {
    opacity: 0;
  }

  7.5%, 21.5% {
    opacity: 1;
  }

  22% {
    opacity: 0;
  }
}

@keyframes a6-a {
  8% {
    opacity: 0;
  }

  9%, 20% {
    opacity: 1;
  }

  21% {
    opacity: 0;
  }
}

@keyframes a7-a {
  10% {
    opacity: 0;
  }

  10.5%, 18.5% {
    opacity: 1;
  }

  19% {
    opacity: 0;
  }
}

@keyframes a8-a {
  11% {
    opacity: 0;
  }

  12%, 17% {
    opacity: 1;
  }

  18% {
    opacity: 0;
  }
}

@keyframes b1-a {
  30% {
    opacity: 0;
  }

  30.5%, 59.5% {
    opacity: 1;
  }

  60% {
    opacity: 0;
  }
}

@keyframes b2-a {
  31% {
    opacity: 0;
  }

  32%, 58% {
    opacity: 1;
  }

  59% {
    opacity: 0;
  }
}

@keyframes b3-a {
  33% {
    opacity: 0;
  }

  33.5%, 56.5% {
    opacity: 1;
  }

  57% {
    opacity: 0;
  }
}

@keyframes b4-a {
  34% {
    opacity: 0;
  }

  35%, 55% {
    opacity: 1;
  }

  56% {
    opacity: 0;
  }
}

@keyframes b5-a {
  36% {
    opacity: 0;
  }

  36.5%, 53.5% {
    opacity: 1;
  }

  54% {
    opacity: 0;
  }
}

@keyframes b6-a {
  37% {
    opacity: 0;
  }

  38%, 52% {
    opacity: 1;
  }

  53% {
    opacity: 0;
  }
}

@keyframes b7-a {
  39% {
    opacity: 0;
  }

  39.5%, 50.5% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }
}

@keyframes b8-a {
  40% {
    opacity: 0;
  }

  41%, 49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@keyframes b9-a {
  42% {
    opacity: 0;
  }

  42.5%, 47.5% {
    opacity: 1;
  }

  48% {
    opacity: 0;
  }
}

@keyframes c1-a {
  62% {
    opacity: 0;
  }

  62.5%, 98.5% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }
}

@keyframes c2-a {
  63% {
    opacity: 0;
  }

  64%, 97% {
    opacity: 1;
  }

  98% {
    opacity: 0;
  }
}

@keyframes c3-a {
  65% {
    opacity: 0;
  }

  65.5%, 95.5% {
    opacity: 1;
  }

  96% {
    opacity: 0;
  }
}

@keyframes c4-a {
  66% {
    opacity: 0;
  }

  67%, 94% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }
}

@keyframes c5-a {
  68% {
    opacity: 0;
  }

  68.5%, 92.5% {
    opacity: 1;
  }

  93% {
    opacity: 0;
  }
}

@keyframes c6-a {
  69% {
    opacity: 0;
  }

  70%, 91% {
    opacity: 1;
  }

  92% {
    opacity: 0;
  }
}

@keyframes c7-a {
  71% {
    opacity: 0;
  }

  71.5%, 89.5% {
    opacity: 1;
  }

  90% {
    opacity: 0;
  }
}

@keyframes c8-a {
  72% {
    opacity: 0;
  }

  73%, 88% {
    opacity: 1;
  }

  89% {
    opacity: 0;
  }
}

@keyframes c9-a {
  74% {
    opacity: 0;
  }

  74.5%, 86.5% {
    opacity: 1;
  }

  87% {
    opacity: 0;
  }
}

@keyframes c10-a {
  75% {
    opacity: 0;
  }

  76%, 85% {
    opacity: 1;
  }

  86% {
    opacity: 0;
  }
}

@keyframes c11-a {
  77% {
    opacity: 0;
  }

  77.5%, 83.5% {
    opacity: 1;
  }

  84% {
    opacity: 0;
  }
}

.btn, button {
  background-color: var(--action-color);
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  border-radius: 500px;
  outline: none;
  text-decoration: none;
  display: block;
}

.read-more {
  width: 200px;
  margin: 1em auto;
  padding: 8px;
}

.contact-btn, .form-btn {
  width: 300px;
  margin: 2em auto;
  padding: 10px;
}

.contact-btn {
  font-size: 1.1em;
}

.form-btn {
  font-size: .8em;
}

.dde-head {
  padding: var(--header-height) 8%;
  z-index: 30;
  background-color: #fff;
  width: 100%;
  height: auto;
  font-size: 3em;
  position: relative;
}

.dde-head p {
  color: var(--grey-400);
  font-style: italic;
}

.dde-img-holder, .dde-tx-holder {
  height: 100%;
}

.dde-img {
  width: 100%;
}

.dde-img svg {
  height: calc(width * 1);
  width: 100%;
}

.orange-img {
  max-width: 70vw;
  height: 40vh;
  margin: auto;
  display: block;
}

.display-images {
  text-align: center;
  width: 100%;
  height: 25vw;
  max-height: 35vh;
  margin-bottom: 15px;
  display: block;
}

.display-images img {
  width: auto;
  height: 100%;
  margin: 7px;
  box-shadow: 3px 3px 4px #0000004d;
}

.display-section {
  border-bottom: solid 1px var(--grey-400);
  padding: .5px 1em;
  font-size: .9em;
}

.display-section h4 {
  color: var(--h4-color);
  margin-top: 1em;
  font-size: 1.2em;
}

.secondary-content > section {
  margin: 0 15vw 8vw;
}

.dde-heading {
  font-size: 1.8em;
  font-weight: bold;
}

@media (width >= 769px) {
  .desktop-none {
    display: none;
  }

  .dde-img-holder {
    float: right;
    width: 50%;
  }

  .dde-img-holder, .dde-tx-holder {
    height: 100%;
  }

  .dde-img-holder {
    padding-top: 50px;
  }

  .dde-head {
    height: 95vh;
  }
}

@media (width <= 768px) {
  .mobile-none {
    display: none;
  }

  .secondary-content > section {
    margin: 0 8vw 8vw;
  }

  .dde-img {
    width: 60%;
  }
}

@media (width <= 450px) {
  .dde-img {
    width: 100%;
  }
}

.contact-window {
  align-content: center;
  max-width: 550px;
  padding-top: 50px;
}

#form-head {
  padding-bottom: 20px;
}

.hidden {
  display: none;
}

input[type="text"], input[type="email"], select, textarea {
  border: 2px solid var(--input-border);
  box-sizing: border-box;
  resize: vertical;
  border-radius: 10px;
  width: 100%;
  margin: 10px 0;
  padding: 12px;
  font-size: .7em;
  transition: border .3s ease-in-out;
}

input:active, select:active, textarea:active, input:focus, select:focus, textarea:focus {
  border: solid 2px var(--input-active);
  outline: none;
}

input, textarea, label {
  font-family: Verdana, Tahoma, sans-serif;
}

.required {
  color: var(--warning-color);
  font-size: .7em;
  position: absolute;
  top: 9px;
  right: 7px;
}

.input-holder {
  width: 100%;
  position: relative;
}

.input-warning {
  border: solid 2px var(--warning-color) !important;
}

@media (width <= 768px) {
  .contact-window {
    margin: 0 5vw;
    padding-top: 0;
  }
}
/*# sourceMappingURL=index.fdf3f8d3.css.map */
