
#a1 { opacity: 0; animation: a1-a var(--hero-anim-time) infinite; }
#a2 { opacity: 0; animation: a2-a var(--hero-anim-time) infinite; }
#a3 { opacity: 0; animation: a3-a var(--hero-anim-time) infinite; }
#a4 { opacity: 0; animation: a4-a var(--hero-anim-time) infinite; }
#a5 { opacity: 0; animation: a5-a var(--hero-anim-time) infinite; }
#a6 { opacity: 0; animation: a6-a var(--hero-anim-time) infinite; }
#a7 { opacity: 0; animation: a7-a var(--hero-anim-time) infinite; }
#a8 { opacity: 0; animation: a8-a var(--hero-anim-time) infinite; }

#b1 { opacity: 0; animation: b1-a var(--hero-anim-time) infinite; }
#b2 { opacity: 0; animation: b2-a var(--hero-anim-time) infinite; }
#b3 { opacity: 0; animation: b3-a var(--hero-anim-time) infinite; }
#b4 { opacity: 0; animation: b4-a var(--hero-anim-time) infinite; }
#b5 { opacity: 0; animation: b5-a var(--hero-anim-time) infinite; }
#b6 { opacity: 0; animation: b6-a var(--hero-anim-time) infinite; }
#b7 { opacity: 0; animation: b7-a var(--hero-anim-time) infinite; }
#b8 { opacity: 0; animation: b8-a var(--hero-anim-time) infinite; }
#b9 { opacity: 0; animation: b9-a var(--hero-anim-time) infinite; }

#c1 { opacity: 0; animation: c1-a var(--hero-anim-time) infinite; }
#c2 { opacity: 0; animation: c2-a var(--hero-anim-time) infinite; }
#c3 { opacity: 0; animation: c3-a var(--hero-anim-time) infinite; }
#c4 { opacity: 0; animation: c4-a var(--hero-anim-time) infinite; }
#c5 { opacity: 0; animation: c5-a var(--hero-anim-time) infinite; }
#c6 { opacity: 0; animation: c6-a var(--hero-anim-time) infinite; }
#c7 { opacity: 0; animation: c7-a var(--hero-anim-time) infinite; }
#c8 { opacity: 0; animation: c8-a var(--hero-anim-time) infinite; }
#c9 { opacity: 0; animation: c9-a var(--hero-anim-time) infinite; }
#c10 { opacity: 0; animation: c10-a var(--hero-anim-time) infinite; }
#c11 { opacity: 0; animation: c11-a var(--hero-anim-time) infinite; }

// letter timing 1.5s on & off (each letter doubled plus "hold") 
    // 8x2+1, 9x2+1, 11x2+1 = multiply by 1.5% = 88.5%
    // "hold" time between words = same as letter timing 
// remainder 11.5% split for "pause" after each word (last word slightly longer) 3.5 3.5 4.5


@keyframes a1-a {
    1% {opacity: 0;}
    1.5%, 27.5% {opacity: 1;}
    30% {opacity: 0;}
}
@keyframes a2-a {
    2% {opacity: 0;}
    3%, 26% {opacity: 1;}
    27% {opacity: 0;}
}
@keyframes a3-a {
    4% {opacity: 0;}
    4.5%, 24.5% {opacity: 1;}
    25% {opacity: 0;}
}
@keyframes a4-a {
    5% {opacity: 0;}
    6%, 23% {opacity: 1;}
    24% {opacity: 0;}
}
@keyframes a5-a {
    7% {opacity: 0;}
    7.5% , 21.5% {opacity: 1;}
    22% {opacity: 0;}
}
@keyframes a6-a {
    8% {opacity: 0;}
    9%, 20% {opacity: 1;}
    21% {opacity: 0;}
}
@keyframes a7-a {
    10% {opacity: 0;}
    10.5%, 18.5% {opacity: 1;}
    19% {opacity: 0;}
}
@keyframes a8-a {
    11% {opacity: 0;}
    12%, 17% {opacity: 1;}
    18% {opacity: 0;}
}


@keyframes b1-a {
    30% {opacity: 0;}
    30.5%, 59.5% {opacity: 1;}
    60% {opacity: 0;}
}
@keyframes b2-a {
    31% {opacity: 0;}
    32%, 58% {opacity: 1;}
    59% {opacity: 0;}
}
@keyframes b3-a {
    33% {opacity: 0;}
    33.5%, 56.5% {opacity: 1;}
    57% {opacity: 0;}
}
@keyframes b4-a {
    34% {opacity: 0;}
    35%, 55% {opacity: 1;}
    56% {opacity: 0;}
}
@keyframes b5-a {
    36% {opacity: 0;}
    36.5% , 53.5% {opacity: 1;}
    54% {opacity: 0;}
}
@keyframes b6-a {
    37% {opacity: 0;}
    38%, 52% {opacity: 1;}
    53% {opacity: 0;}
}
@keyframes b7-a {
    39% {opacity: 0;}
    39.5%, 50.5% {opacity: 1;}
    51% {opacity: 0;}
}
@keyframes b8-a {
    40% {opacity: 0;}
    41%, 49% {opacity: 1;}
    50% {opacity: 0;}
}
@keyframes b9-a {
    42% {opacity: 0;}
    42.5%, 47.5% {opacity: 1;}
    48% {opacity: 0;}
}


@keyframes c1-a {
    62% {opacity: 0;}
    62.5%, 98.5% {opacity: 1;}
    99% {opacity: 0;}
}
@keyframes c2-a {
    63% {opacity: 0;}
    64%, 97% {opacity: 1;}
    98% {opacity: 0;}
}
@keyframes c3-a {
    65% {opacity: 0;}
    65.5%, 95.5% {opacity: 1;}
    96% {opacity: 0;}
}
@keyframes c4-a {
    66% {opacity: 0;}
    67%, 94% {opacity: 1;}
    95% {opacity: 0;}
}
@keyframes c5-a {
    68% {opacity: 0;}
    68.5% , 92.5% {opacity: 1;}
    93% {opacity: 0;}
}
@keyframes c6-a {
    69% {opacity: 0;}
    70%, 91% {opacity: 1;}
    92% {opacity: 0;}
}
@keyframes c7-a {
    71% {opacity: 0;}
    71.5%, 89.5% {opacity: 1;}
    90% {opacity: 0;}
}
@keyframes c8-a {
    72% {opacity: 0;}
    73%, 88% {opacity: 1;}
    89% {opacity: 0;}
}
@keyframes c9-a {
    74% {opacity: 0;}
    74.5%, 86.5% {opacity: 1;}
    87% {opacity: 0;}
}
@keyframes c10-a {
    75% {opacity: 0;}
    76%, 85% {opacity: 1;}
    86% {opacity: 0;}
}
@keyframes c11-a {
    77% {opacity: 0;}
    77.5%, 83.5% {opacity: 1;}
    84% {opacity: 0;}
}


