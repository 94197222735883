.hero {
    position: relative;
    height: 95vh;
    width: 100%;
}

.hero-image-holder {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-right: 90vw;

    #hero-image-position {
        position: relative;
        width: 50px;
        height: 50px;
        transform: rotate(-10deg);
        img{
            position: absolute;
            top: -65vh;
            left: -70vw;
            height: 150vh;
            width: 150vw;
            max-height: 1700px;
            transform-style: preserve-3d;
        }
    }

}


#hero-img-logo{
    transform:
    perspective(5500px)
    rotateY(var(--rotatey))
    rotateX(var(--rotatex));
}


#hero-img-shadow {
    transform:
    perspective(5500px)
    rotateY(var(--rotatey))
    rotateX(var(--rotatex))
    translateZ(-49px);
    filter: blur(5px);
}


#hero-img-grid{
    transform:
    perspective(5500px)
    rotateY(var(--rotatey))
    rotateX(var(--rotatex))
    translateZ(-50px);
}

.hero-text {
    z-index: 10;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 3.5em;
    padding-left: 10vw;
} 

.hero-name { font-size: 1.5em; font-weight: bold; }
.hero-words { position: relative; height: 120px; }

.hero-words p {
    position: absolute;
    top: 0;
    margin-top: 0;
}

#word1{ color: var(--brand-colore-500);  }
#word2{ color: var(--brand-colord-500);  }
#word3{ color: var(--brand-colorb-500);  }



/* responsive styles */
@media all and (max-width: 1200px){
    .hero-image-holder { padding-right: 110vw;}
    .hero-text { padding-left: 20vw; } 
}

@media all and (max-width: 950px){
    .hero-image-holder { padding-right: 70vw; padding-bottom: 35vh;}
    .hero-text { padding-left: 0vw; padding-top: 30vh; font-size: 3em; } 
}

@media all and (max-width: 600px){
    .hero-image-holder { padding-right: 70vw; padding-bottom: 30vh;}
    .hero-text { padding-top: 40vh; font-size: 2.7em; } 
    .hero-words { margin-top: 10vh; }
}

@media all and (max-width: 450px){
    .hero-text { font-size: 2em; } 
}
