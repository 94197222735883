:root {

    --brand-colora-500: #ff00ff;
    --brand-colorb-500: #f700ff;
    --brand-colorc-500: #E300FB;
    --brand-colord-500: #C100F7;
    --brand-colore-500: #9200F1;
    --brand-colorf-500: #5600E9;
    --brand-colorg-500: #4600E7;

    --brand-gradient: linear-gradient(to top left, var(--brand-colora-500), var(--brand-colorg-500));

    --brand-colore-200: #d199f6;
    --brand-colore-300: #c474f9;
    --brand-colore-400: #aa39f5;
    //see above brand colours for e 500
    --brand-colore-600: #8107d3;
    --brand-colore-700: #6705a8;
    --brand-colore-800: #520288;

    --brand-colorf-700: #4203b1;

    --grey-100: rgb(237, 235, 235);
    --grey-200: rgb(219, 217, 217);
    --grey-300: rgb(180, 180, 180);
    --grey-400: rgb(159, 158, 158);
    --grey-500: rgb(128, 128, 128);
    --grey-600: rgb(102, 102, 102);
    --grey-700: rgb(73, 73, 73);
    --grey-800: rgb(44, 44, 44);
    --grey-900: rgb(16, 16, 16);


    --header-height: 70px;
    --header-active: var(--brand-colorc-500);
    --header-hover: var(--brand-colore-500);
    --header-border: var(--brand-colord-500);

    --hero-anim-time: 6s;
    --action-color: var(--brand-colorg-500);
    --highlight-color: var(--brand-colore-800);

    --h1-color: var(--brand-colore-600);
    --h2-color: var(--brand-colore-700);
    --h3-color: var(--brand-colore-800);
    --h4-color: var(--brand-colorf-700);

    //set form & system status variables
    --input-border: var(--brand-colore-200);
    --input-active: var(--brand-colore-500);
    --warning-color: rgb(220, 145, 6);
    --success-color: rgb(64, 208, 112);

    // --test-color: #f2f2f2;
    
}
